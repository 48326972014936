<template>
    <div
        class="full-height pa-10-20"
    >
        <h6>{{ program.name }}</h6>

        <div
            class="bg-white pa-10 box"
        >
            정산기준
            <select
                v-model="search.year"
                class="pa-5-10 bg-white"
                @change="getSearch(1)"
            >
                <option
                    v-for="year in year_items"
                    :key="'year_' + year"
                >{{ year }}
                </option>
            </select>
        </div>

        <div
            class="mt-10 box-ddd"
        >
            <div
                class="pa-10 bg-identify"
            >
                {{ search.date }}
            </div>
            <div class=" bg-white justify-space-between">
                <div class="pa-20 flex-1 border-right bg-icon-round-count">
                    <div class="color-identify">정산 건수</div>
                    <div class="mt-10 font-weight-bold size-px-16">{{ summary.count | makeComma }}건</div>
                </div>

                <div class="pa-20 flex-1 border-right bg-icon-paper">
                    <div class="color-identify">승인 금액</div>
                    <div class="mt-10 font-weight-bold size-px-16">{{ summary.approvalAmount | makeComma }}원</div>
                </div>

                <div class="pa-20 flex-1 border-right bg-icon-calc">
                    <div class="color-identify">수수료(부가세 포함)</div>
                    <div class="mt-10 font-weight-bold size-px-16">{{ summary.feeAndTaxAmount | makeComma }}원</div>
                </div>

                <div class="pa-20 flex-1 border-right bg-icon-calculator">
                    <div class="color-identify">정산 금액</div>
                    <div class="mt-10 font-weight-bold size-px-16">{{ summary.settlementAmount | makeComma }}원</div>
                </div>

            </div>
        </div>

        <div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">
            <div class="justify-end">

                <div>
                    <button
                        class="box mr-10 pa-4-10 size-px-12"
                        @click="toExcel"
                    >
                        <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                        <span class="vertical-middle">엑셀 다운로드</span></button>
                </div>

            </div>
            <table
                v-if="items.length > 0"
                class="mt-10 table table-even top-line-identify"
            >
                <colgroup>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="100px"/>

                </colgroup>
                <thead>
                <tr>
                    <th>정산 기준일</th>
                    <th>정산 시작일</th>
                    <th>정산 종료일</th>
                    <th>정산건수</th>
                    <th>승인금액</th>

                    <th>수수료</th>
                    <th>부가세</th>
                    <th>정산금액</th>
                    <th>최종지급금액</th>
                    <th>실제 수익</th>

                    <th>지급여부</th>
                    <th>상세</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ item.month }}월</td>
                    <td>{{ item.startDate | transDate }}</td>
                    <td>{{ item.endDate | transDate }}</td>
                    <td class="text-left" v-if="item.settlementData.length > 0">
                        <div v-for="sub in item.settlementData" :key="sub.sttlementIdx">
                            <p class="font-weight-bold">
                                {{ sub.settlementType | settlementTypeName }}
                            </p>
                            <p>{{ sub.count }}건</p>
                        </div>
                    </td>
                    <td v-else>-</td>
                    <td class="text-left" v-if="item.settlementData.length > 0">
                        <div v-for="sub in item.settlementData" :key="sub.sttlementIdx">
                            <p class="font-weight-bold">
                                {{ sub.settlementType | settlementTypeName }}
                            </p>
                            <p>{{ sub.approvalAmount | makeComma }}원</p>
                        </div>
                    </td>
                    <td v-else>-</td>
                    <td class="text-left" v-if="item.settlementData.length > 0">
                        <div v-for="sub in item.settlementData" :key="sub.sttlementIdx">
                            <p class="font-weight-bold">
                                {{ sub.settlementType | settlementTypeName }}
                            </p>
                            <p>{{ sub.feeAmount | makeComma }}원</p>
                        </div>
                    </td>
                    <td v-else>-</td>
                    <td class="text-left" v-if="item.settlementData.length > 0">
                        <div v-for="sub in item.settlementData" :key="sub.sttlementIdx">
                            <p class="font-weight-bold">
                                {{ sub.settlementType | settlementTypeName }}
                            </p>
                            <p>{{ sub.taxAmount | makeComma }}원</p>
                        </div>
                    </td>
                    <td v-else>-</td>
                    <td class="text-left" v-if="item.settlementData.length > 0">
                        <div v-for="sub in item.settlementData" :key="sub.sttlementIdx">
                            <p class="font-weight-bold">
                                {{ sub.settlementType | settlementTypeName }}
                            </p>
                            <p>{{ sub.settlementAmount | makeComma }}원</p>
                        </div>
                    </td>
                    <td v-else>-</td>
                    <td class="text-right color-identify font-weight-bold">{{ item.settlementAmount | makeComma }}원</td>
                    <td class="text-right color-identify font-weight-bold">{{ item.returnAmount | makeComma }}원</td>

                    <td :class="'color-' + item.state_color ">{{ item.state_name }}</td>
                    <td>
                        <button
                            class="bg-identify pa-5-10 size-px-12"
                            @click="toDetail(item)"
                        >상세보기
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center mt-10 top-line-identify"
            >
                <div class="text-center">
                    <v-icon
                        class="size-px-48 "
                    >mdi mdi-cloud-off-outline
                    </v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>
        </div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
        ></Pagination>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"

            @finish="close"
        ></Excel>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :top="true"

            title="마이 정산 상세 내역"
            class="full-height"
            width="80%"
            height="80%"

            @close="close"

        >
            <SettlementMypageDetail
                :item="item"
                :year="search.year"
                :type="search.type"

                slot="modal-content"

            ></SettlementMypageDetail>
        </Modal>
    </div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";

import SettlementMypageDetail from "@/view/Settlement/SettlementMypageDetail";

export default {
    name: 'SettlementMypage'
    , components: {SettlementMypageDetail, Modal, Excel, Pagination}
    , data: function () {
        return {
            user: [],
            program: {
                name: '마이 정산 내역'
                , top: true
                , title: true
                , bottom: false
            }
            , search: {
                year: this.date.getThisYear()
            }
            , search_option: {}
            , is_excel: false
            , excel_data: {
                name: '마이 정산 내역'
                , header: [
                    {key: 0, name: '정산 기준월', column: 'month'}
                    , {key: 0, name: '정산 시작일', column: 'startDate'}
                    , {key: 0, name: '정산 종료일', column: 'endDate'}
                    , {key: 0, name: '배달비 정산건수', column: 'quickCount'}
                    , {key: 0, name: '배달비 결제금액', column: 'quickApprovalAmount'}
                    , {key: 0, name: '배달비 수수료', column: 'quickFeeAmount'}
                    , {key: 0, name: '배달비 부가세', column: 'quickTaxAmount'}
                    , {key: 0, name: '배달비 정산금액', column: 'quickSettlementAmount'}
                    , {key: 0, name: '월세 정산건수', column: 'monthlyCount'}
                    , {key: 0, name: '월세 승인금액', column: 'monthlyApprovalAmount'}
                    , {key: 0, name: '월세 수수료', column: 'monthlyFeeAmount'}
                    , {key: 0, name: '월세 부가세', column: 'monthlyTaxAmount'}
                    , {key: 0, name: '월세 정산금액', column: 'monthlySettlementAmount'}
                    , {key: 0, name: '선정산 정산건수', column: 'manualCount'}
                    , {key: 0, name: '선정산 승인금액', column: 'manualApprovalAmount'}
                    , {key: 0, name: '선정산 수수료', column: 'manualFeeAmount'}
                    , {key: 0, name: '선정산 부가세', column: 'manualTaxAmount'}
                    , {key: 0, name: '차감금액', column: 'deductionAmount'}
                    , {key: 0, name: '선정산 정산금액', column: 'preSettlementAmount'}
                    , {key: 0, name: '최종지급금액', column: 'settlementAmount'}
                    , {key: 0, name: '실제수익', column: 'returnAmount'}
                    , {key: 0, name: '지급여부', column: 'settlementState'}

                ]
                , content: null
            }
            , items: []
            , item: {}

            , item_payment: []
            , summary: {}
            , is_modal: false
            , modal_option: {}
            , is_all: false
            , item_confirm: {
                state: ''
                , approval: ''
                , idx: []
            }
            , datePicker: false
            , settlementIds: []
        }
    }
    , computed: {
        item_list: function () {
            let self = this
            return this.items.filter(function (item) {

                for (let i = 0; i < self.codes.settlement_payment_state.length; i++) {
                    if (item.settlementState === self.codes.settlement_payment_state[i].code) {
                        item.state_name = self.codes.settlement_payment_state[i].name
                        item.state_color = self.codes.settlement_payment_state[i].color
                        break
                    }
                }

                item.settlementIds = []
                for (let i = 0; i < item.settlementData.length; i++) {
                    item.settlementIds.push(item.settlementData[i].settlementIdx)
                }
                return item
            })
        }
        , year_items: function () {
            let last = new Date()
            let year = last.getFullYear()
            let data = []

            for (let i = this.codes.start_year; i <= year; i++) {
                data.push(i)
            }

            return data
        }
    }
    , methods: {
        getData: async function () {
            try {
                console.log(this.$layout.onLoading,'asd')
                this.$layout.onLoading()

                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/my/' + this.search.year
                })

                if (result.success) {
                    this.items = result.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                const result2 = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/my/statistics/' + this.search.year
                })

                if (result2.success) {
                    console.log(result2.data, 'list')
                    this.summary = result2.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: result2.message})
                }

            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , toDetail: function (item) {
            this.item = item
            this.is_modal = true
        }
        , reset: function () {
            this.search = {
                page: 1
                , size: this.$route.query.size ? this.$route.query.size : 10
                , search_type: ''
                , search_value: ''
                , status: ''
                , sDate: this.date.getSearchBaseDate('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
            }
        }
        , getSearch: function (page) {

            if (page) {
                this.search.page = page
            }

            this.getData()
        }

        , toExcel: async function () {
            try{
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    ,url: 'calculate/my/excel/' + this.search.year
                })

                if(result.success){
                    this.excel_data.content = result.data
                    this.is_excel = true
                }else{
                    this.$layout.setNotify( { type: 'error', message: result.message})
                }
            }catch(e){
                this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$layout.offLoading()
            }
        }
        , setSearchDateType: function (type) {
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.is_excel = false
        }
        , cancel: async function () {

        }
        , setAgency: function (agency) {
            this.search.branchIdx = agency.branchList
            this.search.distributorIdx = agency.distributorList
            this.search.agencyIdx = agency.agencyList
            this.search.resellerIdx = agency.resellerList
            this.getSearch(this.search.page)
        }
        , setNotify: function ({type, message}) {
            this.$layout.setNotify( {type: type, message: message})
        }
    }
    , mounted() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        this.getData()
    }
    , watch: {
        is_all: {
            handler: function (call) {
                let self = this
                this.items.filter(function (item) {
                    self.$set(item, 'is_select', call)
                })
            }
        }
        , 'search.search_date_type': {
            handler: function (call) {
                let today = this.date.getToday('-')
                this.search.eDate = today
                switch (call) {
                    default:
                    case 'today':
                        this.search.sDate = today
                        break
                    case 'weekly':
                        this.search.sDate = this.date.getLastDate(today, 7, '-')
                        break
                    case 'month':
                        this.search.sDate = this.date.getLastDate(today, 30, '-')
                        break
                    case '3':
                        this.search.sDate = this.date.getLastDate(today, 90, '-')
                        break
                    case '6':
                        this.search.sDate = this.date.getLastDate(today, 180, '-')
                        break
                }
            }
        }
    }
}
</script>

<style>
.bg-icon-paper {
    background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-round-count {
    background: url('../../assets/images/icon/icon-round-count.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calc {
    background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calculator {
    background: url('../../assets/images/icon/icon-calculator.svg') no-repeat 10px center;
    padding-left: 70px;
}
</style>